import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import partitePrenotateReducer from '../features/partite-prenotate/partitePrenotateSlice';
import movimentiReducer from '../features/movimenti/movimentiSlice';
import aggiornamentoReducer from '../features/aggiornamento/aggiornamentoSlice';

export const store = configureStore({
  reducer: {
    partitePrenotate: partitePrenotateReducer,
    movimenti: movimentiReducer,
    aggiornamento: aggiornamentoReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
