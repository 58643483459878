import React from 'react';
import { Auth, SAMLAuthProvider, signInWithPopup } from "firebase/auth";
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

interface LoginProps {
  auth: Auth;
}

export function Login(props: LoginProps) {
  const provider = new SAMLAuthProvider('saml.az-google-workspace');
  const navigate = useNavigate();
  const login = () => {
    signInWithPopup(props.auth, provider).then((result) => {
      navigate('/');
    });
  }
  return (
    <main className="form-login w-100 m-auto text-center">
      <h3 className="mb-3">La sessione è scaduta, per continuare devi effettuare il login.</h3>
      <Button variant="primary" className="w-100" onClick={login}>Login</Button>
    </main>
  );
}