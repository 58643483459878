export const tipoOperazioneDomain = new Map<string, string>([
  ['21', 'Sottoscrizione Iniziale'],
  ['41', 'Versamento Aggiuntivo'],
  ['61', 'Rimborso'],
  ['81', 'Switch']
]);
export const statoDomain = new Map<string, string>([
  ['01', 'Attiva'],
  ['02', 'Riconciliata'],
  ['03', 'Riconciliata Parziale'],
  ['04', 'Scaduta'],
  ['05', 'Non Riconciliata'],
  ['06', 'Cancellata Manuale']
]);
export const causale_tipoOperazioneDomain = new Map<string, Map<string, string>>([
  ['01', new Map<string, string>([
    ['21', 'Sottoscrizione Iniziale'],
    ['81', 'Switch In Iniziale']
  ])],
  ['02', new Map<string, string>([
    ['41', 'Versamento Aggiuntivo'],
    ['81', 'Switch In Successivo']
  ])],
  ['03', new Map<string, string>([
    ['61', 'Rimborso Parziale'],
    ['81', 'Switch Out']
  ])],
  ['04', new Map<string, string>([
    ['61', 'Rimborso Totale'],
    ['81', 'Switch Out']
  ])]
]);
export const codTipoStrumentoDomain = new Map<string, string>([
  ['01', 'Gestito'],
  ['02', 'Assicurativo Finanziario'],
  ['03', 'Economia Reale'],
  ['07', 'Assicurativo Tradizionale']
]);
export const codTipoSottoStrumentoDomain = new Map<string, string>([
  ['A', 'Gestioni patrimoniali mobiliari'],
  ['B', 'Fondi Hedge'],
  ['C', 'Fondi Comuni di investimento'],
  ['D', 'SICAV'],
  ['E', 'Unit Linked a prezzo'],
  ['F', 'Polizze tradizionali rivalutate'],
  ['G', 'Unit Linked'],
  ['H', 'Private Debt'],
  ['I', 'Private Equity'],
  ['J', 'Venture Capital'],
  ['K', 'Liquid Alternative'],
  ['L', 'Real Assets'],
  ['U', 'Polizze vita tradizionali']
]);
export const codTipoOperazioneDomain = new Map<string, string>([
  ['CI', 'CONFERIMENTO INIZIALE'],
  ['CS', 'CONFERIMENTO SUCCESSIVO'],
  ['PP', 'PRELIEVO PARZIALE'],
  ['PT', 'PRELIEVO TOTALE'],
  ['SWIN', 'SWITCH IN'],
  ['SWOUT', 'SWITCH OUT'],
  ['TAIN', 'TRASF.ALTRO INTERM. IN'],
  ['TAOUT', 'TRASF.ALTRO INTERM. OUT'],
  ['TTEIN', 'TRASF.TITOLI ESTERO'],
  ['TTEOUT', 'TRASF.TITOLI ESTERO'],
  ['RMPR', 'RIMBORSO PROGRAMMATO'],
  ['SWPR', 'SWITCH PROGRAMMATO']
]);
export const codModalitaServizioDomain = new Map<string, string>([
  ['CB', 'CONSULENZA BASE'],
  ['SX', 'MAX'],
  ['RTOB', 'RTO BPM'],
  ['MNE', 'MAX NO EXECUTION'],
  ['MX', 'MAX FUND'],
  ['RTOI', 'RTO IMI'],
  ['RDC', 'REGIME DI COMMERCIALIZZAZIONE']
]);
export const perPageDomain = [10, 15, 20];
export const prodottiDomain = new Map<string, string[]>([
  ['FONDI', ['Fondi LUX', 'Fondi ITA', 'AFB']],
  ['POLIZZE', ['Polizze di casa', 'Polizze di case terze']]
]);
export const codStatoListDefault = [
  {value: '01', label: 'Attiva'},
  {value: '03', label: 'Riconciliata Parziale'}
]