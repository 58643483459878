import React from 'react';
import { currencyFormatter, dateFormatter, getTipoOperazione, isDeletable } from "../../shared/functions";
import {statoDomain} from "../../shared/domains";

interface DettaglioProps {
  partitaPrenotata: any;
  callback: (e: any, erase: boolean, pp: any) => void;
  closeModal: () => void;
}

export function DettaglioPartitePrenotate(props: DettaglioProps) {
  const { partitaPrenotata } = props;

  const proceed = (e: any, erase: boolean) => {
    props.closeModal();
    props.callback(e, erase, partitaPrenotata);
  }

  return (
    <>
      <div className="text-end m-2">
        <i className={`bi bi-eraser-fill me-3 icon-hover ${(isDeletable(partitaPrenotata)) ? '' : 'isNotDeletable'}`}
          onClick={(e) => {
            if (isDeletable(partitaPrenotata))
              proceed(e, true);
          }}></i>
        <i className={`bi bi-trash-fill icon-hover ${(isDeletable(partitaPrenotata)) ? '' : 'isNotDeletable'}`}
          onClick={(e) => {
            if (isDeletable(partitaPrenotata))
              proceed(e, false);
          }}></i>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="idAnaPartita" className="fw-bold">ID Partita Prenotata</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.idPartitaPrenotata}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codCliente" className="fw-bold">Codice Cliente</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.codCliente}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="nomeCognomeCliente" className="fw-bold">Nome Cognome Cliente</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.nomeCognomeCliente}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="numSimula" className="fw-bold">Numero Simula</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.numSimula}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codAgente" className="fw-bold">Codice Agente</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.codAgente}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="nomeCognomeAgente" className="fw-bold">Nome Cognome Agente</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.nomeCognomeAgente}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="tipoOperazione" className="fw-bold">Tipo Operazione</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {getTipoOperazione(partitaPrenotata)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="ambito" className="fw-bold">Ambito</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.ambito}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codTitolo" className="fw-bold">Codice Prodotto</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.codTitolo}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="desTitolo" className="fw-bold">Descrizione Prodotto</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.desTitolo}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="quoteSimulate" className="fw-bold">Quote Simulate</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.numQuoteSimulato}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="importoSimulato" className="fw-bold">Importo Simulato</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {currencyFormatter(partitaPrenotata.controvaloreSimulato)}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="importoResiduo" className="fw-bold">Importo Residuo</label>
          </div>
          <div className="p-2 bg-light flex-fill">
          {currencyFormatter(partitaPrenotata.controvaloreResiduo)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="quoteResiduo" className="fw-bold">Quote Residuo</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.numQuoteResiduo}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codMandato" className="fw-bold">Codice Mandato</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.codMandato}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="importoRegolato" className="fw-bold">Importo Regolato</label>
          </div>
          <div className="p-2 bg-light flex-fill">
          {partitaPrenotata.controvaloreRegolato}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="stato" className="fw-bold">Stato</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {statoDomain.get(partitaPrenotata.codStato)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codRapportoGambaIn" className="fw-bold">Rapporto Gamba In</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {partitaPrenotata.codRapportoGambaIn}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codRapportoGambaOut" className="fw-bold">Rapporto Gamba Out</label>
          </div>
          <div className="p-2 bg-light flex-fill">
          {partitaPrenotata.codRapportoGambaOut}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="dataScadenza" className="fw-bold">Data Scadenza</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {dateFormatter(partitaPrenotata.dataScadenza)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="dataAccettazione" className="fw-bold">Data Accettazione</label>
          </div>
          <div className="p-2 bg-light flex-fill">
          {dateFormatter(partitaPrenotata.dataAccettazione)}
          </div>
        </div>
      </div>
    </>
  );
}