import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { UserContext } from "../../App";
import { ITEMS_PER_PAGE } from "../../app/conf";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Paginator } from "../paginator/Paginator";
import { CustomModal } from "../shared/CustomModal/CustomModal";
import { codModalitaServizioDomain, codTipoOperazioneDomain, codTipoSottoStrumentoDomain, codTipoStrumentoDomain } from "../shared/domains";
import { SelectField } from "../shared/FormikFields/SelectField";
import { currencyFormatter, dateFormatter, getFormikValues, getOptionsFromDomain } from "../shared/functions";
import { MovimentiParams } from "../shared/interface";
import { DettaglioListaMovimenti } from "./dettaglio/DettaglioListaMovimenti";
import { selectMovimentiNonRiconciliati, getMovimentiNonRiconciliati, selectPagination } from "./movimentiSlice";

export function ListaMovimenti() {
  const dispatch = useAppDispatch();
  const listaMovimentiNonRiconciliati = useAppSelector(selectMovimentiNonRiconciliati);
  const paginationItem = useAppSelector(selectPagination);
  const user = useContext(UserContext);
  const initialValues: MovimentiParams = {
    codTipoStrumento: '', codTipoSottoStrumento: '', codModalitaServizio: '',
    codAgente: '', codCliente: ''
  };
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [movimento, setMovimento] = useState({} as any);
  const [formValues, setFormValues] = useState({} as MovimentiParams);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(ITEMS_PER_PAGE);

  const handleCloseDetail = () => setShowDetailModal(false);
  const openDetail = (item: any) => {
    setMovimento(item);
    setShowDetailModal(true);
  };

  const paginationChanged = (p: number) => {
    setPage(p);
  };

  const perPageChanged = (perPageNew: number) => {
    setPerPage(perPageNew);
  }

  const handleReset = () => {
    setPage(0);
    setPerPage(ITEMS_PER_PAGE)
    setFormValues(initialValues)
  }

  useEffect(() => {
    if (user?.uid) {
      dispatch(getMovimentiNonRiconciliati({ ...getFormikValues(formValues), page: page, per_page: perPage }));
    }
  }, [dispatch, user, formValues, page, perPage]);

  return (
    <>
      <CustomModal
        show={showDetailModal}
        cancelFnc={handleCloseDetail}
        size="lg"
        title="Dettaglio Movimenti Non Riconciliati"
        component={<DettaglioListaMovimenti movimento={movimento} />}
        confirmFnc={handleCloseDetail}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setFormValues(values);
          setPage(0);
          setPerPage(ITEMS_PER_PAGE);
          actions.setSubmitting(false);
        }}>
        <Form>
          <div className="d-flex align-items-center">
            <div className="me-3 flex-fill">
              <div className="d-flex flex-row mb-3">
                <Field name="codCliente" className="form-control me-1" placeholder="Codice Cliente"/>
                <Field name="codAgente" className="form-control ms-1" placeholder="Codice Agente"/>
              </div>
              <Field className="mb-2" name="codTipoStrumento" component={SelectField} options={getOptionsFromDomain(codTipoStrumentoDomain)} placeholder="Tipologia Strumento" />
              <Field className="mb-2" name="codTipoSottoStrumento" component={SelectField} options={getOptionsFromDomain(codTipoSottoStrumentoDomain)} placeholder="Sottotipologia Strumento" />
              <Field className="mb-2" name="codModalitaServizio" component={SelectField} options={getOptionsFromDomain(codModalitaServizioDomain)} placeholder="Modalità Servizio" />
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="m-2">
                <Button className="btn btn-lg" variant="primary" type="submit">Cerca</Button>
              </div>
              <div className="m-2">
                <Button className="btn btn-lg" variant="primary" type="reset" onClick={handleReset}>Reset</Button>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      {listaMovimentiNonRiconciliati.length > 0 ?
        <>
          <Paginator page={page} perPage={perPage} totalItems={paginationItem.count}
            callback={paginationChanged} callbackPerPage={perPageChanged}></Paginator>
          <Table hover className="mt-5" responsive>
            <thead>
              <tr>
                <th>Tipologia Strumento</th>
                <th>Sottotipologia Strumento</th>
                <th>Modalità servizio</th>
                <th>Codice Agente</th>
                <th>Codice Cliente Esecutore</th>
                <th>Codice Prodotto</th>
                <th>Codice Mandato</th>
                <th>Progressivo Movimento</th>
                <th>Tipo Operazione</th>
                <th>Data Regolamento</th>
                <th>Importo Regolato</th>
                <th>Motivazione Scarto</th>
              </tr>
            </thead>
            <tbody>
              {listaMovimentiNonRiconciliati.map((item: any, index) =>
                <tr key={index} onClick={() => openDetail(item)} className="clickable">
                  <td>{codTipoStrumentoDomain.get(item.codTipoStrumento)}</td>
                  <td>{codTipoSottoStrumentoDomain.get(item.codTipoSottoStrumento)}</td>
                  <td>{codModalitaServizioDomain.get(item.codModalitaServizio)}</td>
                  <td>{item.codAgente}</td>
                  <td>{item.codClienteEsecutore}</td>
                  <td>{item.codTitolo}</td>
                  <td>{item.codMandato}</td>
                  <td>{item.codMovimento}</td>
                  <td>{codTipoOperazioneDomain.get(item.codTipoOperazione)}</td>
                  <td>{dateFormatter(item.dataRegolamento)}</td>
                  <td>{currencyFormatter(item.controvaloreRegolato)}</td>
                  <td>{item.motivazioneScarto}</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Paginator page={page} perPage={perPage} totalItems={paginationItem.count}
            callback={paginationChanged} callbackPerPage={perPageChanged}></Paginator>
        </> : <div className="d-flex justify-content-center mt-3"><h4>Nessun risultato disponibile.</h4></div>
      }
    </>
  );
}
