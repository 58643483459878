import axios from 'axios';
import React, { useState } from 'react'
import { Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import { REACT_APP_BASE_URL } from '../../app/conf';

export function AxiosInterceptor({ children }: any) {
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState('');
  const hideToast = () => setShowToast(false);
  const navigate = useNavigate();

  axios.defaults.baseURL = REACT_APP_BASE_URL;
  // Request
  axios.interceptors.request.use(function (config) {
    setShowSpinner(true);
    return config;
  }, function (error) {
    setShowSpinner(false);
    setError(error.message);
    setShowToast(true);
    return Promise.reject(error);
  });

  // Response
  axios.interceptors.response.use(function (response) {
    setShowSpinner(false);
    return response;
  }, function (error) {
    setShowSpinner(false);
    setError(error.message);
    setShowToast(true);
    if (error.response.status === 401) {
      navigate('/login');
    }
    return Promise.reject(error);
  });

  return (
    <>
      {showSpinner &&
        <div className="spinner-container">
          <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Caricamento...</span>
          </Spinner>
        </div>
      }
      <ToastContainer position="top-end">
        <Toast onClose={hideToast} autohide delay={5000} show={showToast}>
          <Toast.Header>
            <strong className="me-auto text-danger">Errore</strong>
          </Toast.Header>
          <Toast.Body>{error}</Toast.Body>
        </Toast>
      </ToastContainer>
      {children}
    </>
  );
}