declare global {
  interface Window {
    REACT_APP_API_KEY: string;
    REACT_APP_AUTH_DOMAIN: string;
    REACT_APP_BASE_URL: string;
    REACT_APP_TENANT_ID: string;
  }
}
const REACT_APP_API_KEY = window.REACT_APP_API_KEY || '';
const REACT_APP_AUTH_DOMAIN = window.REACT_APP_AUTH_DOMAIN || '';
const REACT_APP_BASE_URL = window.REACT_APP_BASE_URL || '';
const REACT_APP_TENANT_ID = window.REACT_APP_TENANT_ID || '';
const ITEMS_PER_PAGE = 10;
const CURRENCY_FORMAT = {
  significantDigits: 2,
  thousandsSeparator: '.',
  decimalSeparator: ',',
  symbol: '€'
}
export {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_BASE_URL,
  REACT_APP_TENANT_ID,
  ITEMS_PER_PAGE,
  CURRENCY_FORMAT
};