import React from 'react'
import Select from 'react-select'

export const SelectField = ({ placeholder, options, field, form, className }: any) => (
  <Select
    key={`key_${field.value}`}
    options={options}
    name={field.name}
    placeholder={placeholder}
    isClearable
    className={className}
    value={options ? options.find((option: any) => option.value === field.value) : ''}
    onChange={(option: any) => form.setFieldValue(field.name, option?.value)}
    onBlur={field.onBlur}
  />
)