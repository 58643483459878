import { Pagination } from "react-bootstrap";
import React from 'react';
import { getOptionsFromDomainList } from "../shared/functions";
import { perPageDomain } from "../shared/domains";

interface PaginationParams {
  totalItems: number;
  page: number;
  perPage: number;
  callback: (p: number) => void;
  callbackPerPage: (p: number) => void;
}

export function Paginator(props: PaginationParams) {
  const { page, perPage, totalItems, callback, callbackPerPage } = props;
  const pagesList = Array.from({ length: Math.ceil(totalItems / perPage) }, (_, i) => i + 1);
  const pagesPerPaginator = 9;
  return (
    <>
      <div className="d-flex flex-wrap justify-content-end m-2 my-3 text-center z-low-priority">
        <div className="d-flex align-items-center">
          {page === 0 &&
            <select className="form-select me-3" name="perPageOption" value={perPage} onChange={(event: any) => callbackPerPage(Number(event.target.value))}>
              {getOptionsFromDomainList(perPageDomain)}
            </select>
          }
          <Pagination className="mb-0">
            <Pagination.First disabled={page === 0} onClick={() => callback(0)}/>
            <Pagination.Prev disabled={page === 0} onClick={() => callback(page - 1)} />

            {page + 1 > Math.floor(pagesPerPaginator / 2) + 1 &&
              <Pagination.Ellipsis disabled/>
            }

            {pagesList.filter((i:number) =>
                (page + 1 <= (Math.floor(pagesPerPaginator)/ 2) + 1 )  ?
                    i <= pagesPerPaginator :
                (page + 1 >= pagesList.length - (Math.floor(pagesPerPaginator)/ 2) + 1 ) ?
                    i > pagesList.length - pagesPerPaginator :
                i >= page + 1 - Math.floor(pagesPerPaginator / 2) &&
                i <= page + 1 + Math.floor(pagesPerPaginator / 2))
                .map((i) =>
              <Pagination.Item
                key={i}
                active={i === (page + 1)}
                disabled={i === (page + 1)}
                onClick={() => callback(i - 1)}>
                {i}
              </Pagination.Item>)
            }

            {page + 1 < pagesList.length - Math.floor(pagesPerPaginator / 2) &&
                <Pagination.Ellipsis disabled/>
            }

            <Pagination.Next disabled={pagesList.length === (page + 1)} onClick={() => callback(page + 1)} />
            <Pagination.Last disabled={pagesList.length === (page + 1)} onClick={() => callback(pagesList.length - 1)}/>
          </Pagination>
        </div>
      </div>
    </>
  )
}