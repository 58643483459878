import React from 'react'
import Select from 'react-select'

export const MultiSelectField = ({ placeholder, options, field, form }: any) => (
  <Select
    key={`key_${field.value}`}
    options={options}
    name={field.name}
    isMulti
    placeholder={placeholder}
    isClearable
    value={field.value}
    onChange={(option: any) => {form.setFieldValue(field.name, option); console.log(option)}}
    onBlur={field.onBlur}
    className="basic-multi-select"
    classNamePrefix="select"
  />
)