import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getAPIDefaultState } from "../shared/functions";
import { AggiornaPartitePrenotateParams, RecuperaPartitePrenotateParams, Status } from "../shared/interface";
import { aggiornaPartitePrenotate, recuperaPartitePrenotate } from "./partitePrenotateAPI";

const initialState = {
  listaPartitePrenotate: getAPIDefaultState(),
  listaPartitePrenotateStatus: Status.idle,
  aggiornaPartitePrenotateStatus: Status.idle
}

export const getPartitePrenotate = createAsyncThunk(
  'partitePrenotate/recuperaPartitePrenotate',
  async (filtri?: RecuperaPartitePrenotateParams) => {
    const response = await recuperaPartitePrenotate(filtri);
    return response.data;
  }
);

export const putPartitePrenotate = createAsyncThunk(
  'partitePrenotate/aggiornaPartitePrenotate',
  async (filtri: AggiornaPartitePrenotateParams) => {
    const response = await aggiornaPartitePrenotate(filtri);
    return response.data;
  }
);

export const partitePrenotateSlice = createSlice({
  name: 'partitePrenotate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartitePrenotate.pending, (state) => {
        state.listaPartitePrenotateStatus = Status.start;
      })
      .addCase(getPartitePrenotate.fulfilled, (state, action) => {
        state.listaPartitePrenotateStatus = Status.success;
        state.listaPartitePrenotate = action.payload;
      })
      .addCase(getPartitePrenotate.rejected, (state) => {
        state.listaPartitePrenotateStatus = Status.failed;
      })
      .addCase(putPartitePrenotate.pending, (state) => {
        state.aggiornaPartitePrenotateStatus = Status.start;
      })
      .addCase(putPartitePrenotate.fulfilled, (state) => {
        state.aggiornaPartitePrenotateStatus = Status.success;
      })
      .addCase(putPartitePrenotate.rejected, (state) => {
        state.aggiornaPartitePrenotateStatus = Status.failed;
      });
  },
});

export const selectPartitePrenotate = (state: RootState) => state.partitePrenotate.listaPartitePrenotate.data;
export const selectPagination = (state: RootState) => state.partitePrenotate.listaPartitePrenotate.pagination;
export const selectAggiornaStatus = (state: RootState) => state.partitePrenotate.aggiornaPartitePrenotateStatus;

export default partitePrenotateSlice.reducer;