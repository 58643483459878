export enum Status {
  'idle',
  'start',
  'success',
  'failed'
};
export interface AggiornamentoProduct {
  tipoProdotto: string;
  valoreSoglia: string;
}
export interface RecuperaPartitePrenotateParams {
  codCliente?: string;
  nomeCognomeCliente?: string,
  codAgente?: string;
  nomeCognomeAgente?: string,
  numSimula?: string;
  codTipoOperazione?: string;
  codTitolo?: string;
  desTitolo?: string,
  codMandato?: string;
  codStatoList?: any[];
  page?: number;
  per_page?: number;
}
export interface AggiornaPartitePrenotateParams {
  idPartitaPrenotata: string,
  controvaloreRegolatoNew: number,
  controvaloreResiduoNew: number,
  numQuoteResiduo: number,
  codStato: string
}
export interface MovimentiParams {
  codAgente?: string,
  codCliente?: string,
  codTipoStrumento?: string;
  codTipoSottoStrumento?: string;
  codModalitaServizio?: string;
  page?: number;
  per_page?: number;
}
export interface FormField {
  name?: string,
  placeholder: string,
  domain?: Map<string, string>,
  className?: any,
  type?: string,
  numFill?: number
}

export interface FormFilter {
  base: FormField[],
  advanced: FormField[]
}