import React from 'react';
import { codModalitaServizioDomain, codTipoOperazioneDomain, codTipoSottoStrumentoDomain, codTipoStrumentoDomain } from '../../shared/domains';
import { currencyFormatter, dateFormatter } from '../../shared/functions';

interface DettaglioProps {
  movimento: any;
}

export function DettaglioListaMovimenti(props: DettaglioProps) {
  const { movimento } = props;
  return (
    <>
      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codTitolo" className="fw-bold">Codice Prodotto</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codTitolo}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codTipoStrumento" className="fw-bold">Tipologia Strumento</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {codTipoStrumentoDomain.get(movimento.codTipoStrumento)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codTipoSottoStrumento" className="fw-bold">Sottotipologia Sottostrumento</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {codTipoSottoStrumentoDomain.get(movimento.codTipoSottoStrumento)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codModalitaServizio" className="fw-bold">Modalità Servizio</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {codModalitaServizioDomain.get(movimento.codModalitaServizio)}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codAgente" className="fw-bold">Codice Agente</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codAgente}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="nomeAgente" className="fw-bold">Nome Agente</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.nomeAgente}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codClienteEsecutore" className="fw-bold">Codice Cliente Esecutore</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codClienteEsecutore}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="nomeClienteEsecutore" className="fw-bold">Nome Cliente Esecutore</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.nomeClienteEsecutore}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codClienteOrdinante" className="fw-bold">Codice Cliente Ordinante</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codClienteOrdinante}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="nomeClienteOrdinante" className="fw-bold">Nome Cliente Ordinante</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.nomeClienteOrdinante}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codDossier" className="fw-bold">Codice Dossier</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codDossier}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codMandato" className="fw-bold">Codice Mandato</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codMandato}
          </div>
        </div>
      </div>


      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="numRaccomandazione" className="fw-bold">Numero Raccomandazione</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.numRaccomandazione}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codMovimento" className="fw-bold">Codice Movimento</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codMovimento}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="codTipoOperazione" className="fw-bold">Tipo Operazione</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {codTipoOperazioneDomain.get(movimento.codTipoOperazione)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="controvaloreRegolato" className="fw-bold">Importo Regolato</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {currencyFormatter(movimento.controvaloreRegolato)}
          </div>
        </div>
      </div>

      <div className="d-flex flex-row m-2 text-center">
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="dataRegolamento" className="fw-bold">Data Regolamento</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {dateFormatter(movimento.dataRegolamento)}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="motivazioneScarto" className="fw-bold">Motivazione Scarto</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.motivazioneScarto}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="nomeClienteOrdinante" className="fw-bold">Cod Rapporto Gamba In</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codRapportoGambaIn}
          </div>
        </div>
        <div className="d-flex flex-column border border-dark m-1 detail-box">
          <div className="p-2 detail-header">
            <label htmlFor="nomeClienteOrdinante" className="fw-bold">Cod Rapporto Gamba Out</label>
          </div>
          <div className="p-2 bg-light flex-fill">
            {movimento.codRapportoGambaOut}
          </div>
        </div>
      </div>
    </>
  );
}
