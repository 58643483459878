import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { UserContext } from "../../App";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CustomModal } from "../shared/CustomModal/CustomModal";
import { prodottiDomain } from "../shared/domains";
import { AggiornamentoProduct, Status } from "../shared/interface";
import { getPeriodoValidita, putAggiornaPeriodoValidita, selectAggiornaStatus, selectPeriodoValidita } from "./aggiornamentoSlice";

export function AggiornamentoValiditaMax() {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [product, setProduct] = useState({} as AggiornamentoProduct);
  const listaPeriodoValidita = useAppSelector(selectPeriodoValidita);
  const aggiornaStatus = useAppSelector(selectAggiornaStatus);
  const handleClose = () => setShowModal(false);
  const user = useContext(UserContext);

  const proceed = () => {
    dispatch(putAggiornaPeriodoValidita(product));
    handleClose();
  };

  const stopEvent = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (user?.uid) {
      dispatch(getPeriodoValidita());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (aggiornaStatus === Status.success) {
      dispatch(getPeriodoValidita())
    }
  }, [aggiornaStatus, dispatch]);

  return (
    <>
      <CustomModal
        show={showModal}
        cancelFnc={handleClose}
        showCancel
        title="Attenzione"
        component={
          <>
            Modificando il periodo di validità verrà aggiornata la data di scadenza delle partite prenotate dei prodotti <strong>{product.tipoProdotto}</strong>
          </>
        }
        confirmFnc={proceed}
      />
      <div className="row row-cols-4 justify-content-center">
        {listaPeriodoValidita.map((item: AggiornamentoProduct, index) =>
          <Card className="card-container col p-0 m-4" key={`${index}_${!!item.valoreSoglia}`}>
            <Card.Img variant="top" src="placeholder.png" />
            <Card.Body>
              <Card.Title className="text-center"><h2>{item.tipoProdotto}</h2></Card.Title>
            </Card.Body>
            <Card.Body>
              <Accordion>
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header>Dettaglio</Accordion.Header>
                  <Accordion.Body>
                    {prodottiDomain.get(item.tipoProdotto)?.map((p, i) => <div key={i}>{p}</div>)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
            <Card.Body>
              <Formik
                initialValues={{ valoreSoglia: item.valoreSoglia || '', noScadenza: item.valoreSoglia ? false : true }}
                onSubmit={(values: any, actions) => {
                  actions.setSubmitting(false);
                  setProduct({ ...item, valoreSoglia: values.noScadenza ? '-1' : values.valoreSoglia.toString() });
                  setShowModal(true);
                }}
                validate={(values) => {
                  if (!values.valoreSoglia) {
                    return { valoreSoglia: 'Campo obbligatorio.' };
                  }
                }}>
                {({ isValid, dirty, values, submitForm }) => (
                  <Form>
                    <div className="d-flex flex-row align-items-center justify-content-around">
                      <div className="me-3">
                        <Field id="valoreSoglia" name="valoreSoglia" className="form-control" type="number" min="1" disabled={true} onClick={stopEvent} />
                      </div>
                      <div className="form-check me-3">
                        <Field id={`noScadenza_${index}`} name="noScadenza" className="form-check-input" type="checkbox" onClick={stopEvent} disabled={true}/>
                        <label className="form-check-label" htmlFor={`noScadenza_${index}`} onClick={stopEvent}>
                          No Scadenza
                        </label>
                      </div>
                      <div hidden>
                        <Button className="d-flex" variant="primary" disabled={!dirty || !isValid} onClick={submitForm}>Aggiorna</Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
}
