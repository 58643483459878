import React, { ReactElement } from "react";
import { CURRENCY_FORMAT } from "../../app/conf";
import { causale_tipoOperazioneDomain } from "./domains";

export const getOptionsFromDomain = (domain?: Map<string, string>) => {
  if(domain === null || domain === undefined)
    return [];

  let options: any[] = [];
  domain.forEach((v, k) => {
    options.push({ value: k, label: v });
  });
  return options;
};

export const getOptionsFromDomainList = (domain: Array<number>) => {
  let options: ReactElement[] = [];
  domain.forEach((element, i) => options.push(<option key={i} value={element}>{element}</option>));
  return options;
};

export const getTipoOperazione = (partitaPrenotata: any) =>
  causale_tipoOperazioneDomain.get(partitaPrenotata.causaleMovimento)
    ?.get(partitaPrenotata.codTipoOperazione);

export const getFormikValues = (values: any) => {
  let res: any = {};
  for (let [key, value] of Object.entries(values)) {
    if (value) {
      res[key] = value;
    }
  }
  return res;
}

export const dateFormatter = (date: string) => {
  if (!date) { return };
  const YEAR = 0;
  const MONTH = 1;
  const DAY = 2;

  const dateArray = date.split("-");
  return dateArray[DAY] + "/" + dateArray[MONTH] + "/" + dateArray[YEAR];
}

export const currencyFormatter = (value: number) => {
if(value === null || value === undefined)
  return null;

  let valueString = value.toFixed(CURRENCY_FORMAT.significantDigits)

  const [currency, decimal] = valueString.split('.')
  return `${CURRENCY_FORMAT.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    CURRENCY_FORMAT.thousandsSeparator
  )}${CURRENCY_FORMAT.decimalSeparator}${decimal}`
}

export const isDeletable = (item: any) => {
  return item.codStato === "01" || item.codStato === "03";    //deletable solo se 01 o 03
}

/** return also number significant (no 0)*/
const codCliSignificant = (e: any) => {
  const inputString = e.target.value.toString();
  for (var i = 0; i < inputString.length; i++) {
    if (inputString[i] !== '0') {
      if (i === inputString.length - 1 && i === 0)
        return inputString;
      return inputString.substring(i, inputString.length)
    }
  }
  return '';
}

export const handleTextInput = (e: any, setFieldValue: any, numDigit: number) => {
  var codCliSigni = codCliSignificant(e);

  if (codCliSigni === '') {
    setFieldValue(e.target.name, codCliSigni);
  }
  else {
    if (codCliSigni.length <= numDigit) {
      const numZero = numDigit - codCliSigni.length;

      var zeros = '';
      for (var i = 0; i < numZero; i++)
        zeros = zeros + '0';

      setFieldValue(e.target.name, zeros + codCliSigni);
    }
  }
}

export const getAPIDefaultState = () => {
  return { data: [], metadata: {}, pagination: { count: 0, page: 0, per_page: 0 } };
}