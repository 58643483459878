import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AggiornamentoProduct, Status } from "../shared/interface";
import { aggiornaPeriodoValidita, recuperaPeriodoValidita } from "./aggiornamentoAPI";

const initialState = {
  aggiornaPeriodoValiditaStatus: Status.idle,
  recuperaPeriodoValiditaStatus: Status.idle,
  listaPeriodoValidita: new Array<AggiornamentoProduct>()
}

export const putAggiornaPeriodoValidita = createAsyncThunk(
  'movimenti/aggiornaPeriodoValidita',
  async (filtri?: AggiornamentoProduct) => {
    const response = await aggiornaPeriodoValidita(filtri);
    return response.data;
  }
);

export const getPeriodoValidita = createAsyncThunk(
  'movimenti/recuperaPeriodoValidita',
  async () => {
    const response = await recuperaPeriodoValidita();
    return response.data?.data;
  }
);

export const aggiornamentoSlice = createSlice({
  name: 'movimenti',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(putAggiornaPeriodoValidita.pending, (state) => {
        state.aggiornaPeriodoValiditaStatus = Status.start;
      })
      .addCase(putAggiornaPeriodoValidita.fulfilled, (state, action) => {
        state.aggiornaPeriodoValiditaStatus = Status.success;
      })
      .addCase(putAggiornaPeriodoValidita.rejected, (state) => {
        state.aggiornaPeriodoValiditaStatus = Status.failed;
      })
      .addCase(getPeriodoValidita.pending, (state) => {
        state.recuperaPeriodoValiditaStatus = Status.start;
      })
      .addCase(getPeriodoValidita.fulfilled, (state, action) => {
        state.recuperaPeriodoValiditaStatus = Status.success;
        state.listaPeriodoValidita = action.payload;
      })
      .addCase(getPeriodoValidita.rejected, (state) => {
        state.recuperaPeriodoValiditaStatus = Status.failed;
      });
  }
});

export const selectPeriodoValidita = (state: RootState) => state.aggiornamento.listaPeriodoValidita;
export const selectAggiornaStatus = (state: RootState) => state.aggiornamento.aggiornaPeriodoValiditaStatus;

export default aggiornamentoSlice.reducer;