import axios from "axios";
import { AggiornamentoProduct } from "../shared/interface";

export async function aggiornaPeriodoValidita(params?: AggiornamentoProduct) {
  return await axios
    .put('/enginePortafoglioBe/v2/aggiornaPeriodoValidita', { ...params })
    .then((response) => response);
}

export async function recuperaPeriodoValidita() {
  return await axios
    .get('/enginePortafoglioBe/v2/recuperaPeriodoValidita')
    .then((response) => response);
}