import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getAPIDefaultState } from "../shared/functions";
import { MovimentiParams, Status } from "../shared/interface";
import { letturaMovimentiNonRiconciliati } from "./movimentiAPI";

const initialState = {
  listaMovimentiNonRiconciliati: getAPIDefaultState(),
  listaMovimentiNonRiconciliatiStatus: Status.idle,
}

export const getMovimentiNonRiconciliati = createAsyncThunk(
  'movimenti/letturaMovimentiNonRiconciliati',
  async (filtri?: MovimentiParams) => {
    const response = await letturaMovimentiNonRiconciliati(filtri);
    return response.data;
  }
);

export const movimentiSlice = createSlice({
  name: 'movimenti',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMovimentiNonRiconciliati.pending, (state) => {
        state.listaMovimentiNonRiconciliatiStatus = Status.start;
      })
      .addCase(getMovimentiNonRiconciliati.fulfilled, (state, action) => {
        state.listaMovimentiNonRiconciliatiStatus = Status.success;
        state.listaMovimentiNonRiconciliati = action.payload;
      })
      .addCase(getMovimentiNonRiconciliati.rejected, (state) => {
        state.listaMovimentiNonRiconciliatiStatus = Status.failed;
      });
  }
});

export const selectMovimentiNonRiconciliati = (state: RootState) => state.movimenti.listaMovimentiNonRiconciliati.data;
export const selectPagination = (state: RootState) => state.movimenti.listaMovimentiNonRiconciliati.pagination;

export default movimentiSlice.reducer;