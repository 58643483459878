import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ListaPartitePrenotate } from './features/partite-prenotate/lista/ListaPartitePrenotate';
import { ListaMovimenti } from './features/movimenti/ListaMovimenti';
import React, { ReactElement, useEffect, useState } from 'react';
import { AggiornamentoValiditaMax } from './features/aggiornamento/AggiornamentoValiditaMax';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { Login } from './features/login/Login';
import { AxiosInterceptor } from './features/axios/AxiosInterceptor';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import { Button } from "react-bootstrap";
import logo from "./assets/logo/logo.png";
import { REACT_APP_API_KEY, REACT_APP_AUTH_DOMAIN, REACT_APP_TENANT_ID } from './app/conf';

const auth = getAuth(initializeApp({
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN
}));
auth.tenantId = REACT_APP_TENANT_ID!;

export const UserContext = React.createContext<User | null>(null);

interface RouteImpl {
  name: string;
  path: string;
  element: ReactElement;
  icon: string;
}

const routeList: Array<RouteImpl> = [{
  name: 'Gestione Partite Prenotate',
  path: 'partite-prenotate',
  element: <ListaPartitePrenotate />,
  icon: 'bi-list-check'
}, {
  name: 'Gestione Movimenti Non Riconciliati',
  path: 'lista-movimenti',
  element: <ListaMovimenti />,
  icon: 'bi-list-task'
}, {
  name: 'Aggiornamento Periodo Validità',
  path: 'aggiornamento',
  element: <AggiornamentoValiditaMax />,
  icon: 'bi-pencil-square'
}];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const isLocationEqualToPath = (route: RouteImpl) => route.path === location.pathname.substring(1);

  useEffect(() => {
    document.title = routeList.find((route) => isLocationEqualToPath(route))?.name || '';
  });


  const logoutButton = () => {
    setUser(null);
    axios.defaults.headers.common['Authorization'] = '';
    auth.signOut();
  }


  // route change
  useEffect(() => {

    const logout = () => {
      setUser(null);
      auth.signOut();
      navigate('login');
    }

    onAuthStateChanged(auth, async (usr) => {
      if (usr) {
        const token = await usr.getIdToken(true);
        if (!token) {
          logout();
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        setUser(usr);
      } else {
        axios.defaults.headers.common['Authorization'] = '';
        logout();
      }
    }
    );
    setShowSidebar(false)
  }, [location.pathname, navigate])

  const toggleSidebar = () => setShowSidebar(!showSidebar);
  return (
    <>
      {/* sidebar */}
      <div className={`d-flex flex-column flex-shrink-0 p-3 bg-light sidebar ${(showSidebar) ? 'sidebar-active' : ''}`}>
        <h4 className="mb-3">{auth.currentUser?.email}</h4>
        <Button variant="primary" onClick={logoutButton}>Logout</Button>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          {routeList.map((route) =>
            <li className="nav-item" key={route.name}>
              <Link to={route.path} className={`nav-link d-flex align-items-center ${isLocationEqualToPath(route) ? 'active' : ''}`}>
                <i className={`bi me-2 ${route.icon}`}></i>
                {route.name}
              </Link>
            </li>)}
        </ul>
        <hr />
        <p className="mb-0">Versione 1.0.0</p>
      </div>
      {/* content */}
      <div className={(showSidebar) ? 'content-with-sidebar' : 'full-width'}>
        <header className="p-3 mb-3 border-bottom header-background">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <Link to="#" onClick={toggleSidebar} className={`sidebar-toggle col ${(!user ? 'link-disabled' : '')}`}>
              <i className="bi bi-list"></i>
            </Link>
            <h1 className="text-white col-8 text-center">{routeList.find((route) => isLocationEqualToPath(route))?.name}</h1>
            <a href="/" className={!user ? 'link-disabled col' : 'col'}>
              <img src={logo} alt="logo" width="185" height="90" className="logo"></img>
            </a>
          </div>
        </header>
        <div className="p-4">
          <UserContext.Provider value={user}>
            <AxiosInterceptor>
              <Routes>
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/" element={<Navigate to="partite-prenotate" />}></Route>
                <Route path="login" element={<Login auth={auth} />} ></Route>
                {
                  routeList.map((route) => <Route key={route.name} path={route.path} element={route.element}></Route>)
                }
              </Routes>
            </AxiosInterceptor>
          </UserContext.Provider>
        </div>
      </div >
    </>
  );
}

export default App;
