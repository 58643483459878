import axios from "axios";
import { AggiornaPartitePrenotateParams, RecuperaPartitePrenotateParams } from "../shared/interface";

export async function recuperaPartitePrenotate(filtri?: RecuperaPartitePrenotateParams) {
  let bareURLRecupera = "/enginePortafoglioBe/v2/recuperaPartitePrenotateConsolle";
  const paramArray = filtri?.codStatoList ? filtri?.codStatoList.map((x:any) => {return `codStatoList=${x}`}) : null;
  
  if(paramArray && paramArray.length > 0)
    bareURLRecupera = bareURLRecupera + "?" + paramArray.join("&");

  const filtriNoArray = {...filtri};
  delete filtriNoArray['codStatoList'];
  
  return await axios
      .get(bareURLRecupera, { params: { ...filtriNoArray} })
      .then((response) => response);
}

export async function aggiornaPartitePrenotate(filtri: AggiornaPartitePrenotateParams) {
  let { idPartitaPrenotata, ...f } = filtri;
  return await axios
    .put('/enginePortafoglioBe/v2/aggiornaPartitePrenotate', { ...f }, { params: { idPartitaPrenotata: idPartitaPrenotata } })
    .then((response) => response);
}