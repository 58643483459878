import React, { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";

interface ModalType {
  show: boolean;
  cancelFnc: () => void;
  confirmFnc: () => void;
  showCancel?: boolean;
  title: string;
  component: ReactElement;
  size?: "sm" | "lg" | "xl";
  confirmText?: string;
}

export function CustomModal(props: ModalType) {
  return (
    <Modal show={props.show} onHide={props.cancelFnc} backdrop="static" size={props.size}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.component}
      </Modal.Body>
      <Modal.Footer>
        {props.showCancel &&
          <Button variant="secondary" onClick={props.cancelFnc}>
            Annulla
          </Button>}
        <Button variant="primary" type="submit" onClick={props.confirmFnc}>
          {props.confirmText || 'Ok'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
